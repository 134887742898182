import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthenService } from '../service/authen.service';
import { ApptokenService } from '../service/apptoken.service';

export const authenGuard: CanActivateFn = (route, state) => {
  const appTokenSV = inject(ApptokenService)
  const router = inject(Router)
  
  const keyToken: string = localStorage.getItem("token") as string
  const role = JSON.parse(keyToken)
  // console.log(appTokenSV.payload)
  // appTokenSV.genToken().subscribe() ดักlogin จาก ระบบอื่น
  if(role){ //check if login
    return true
  }
  // console.log('test')
 // return back to login
  return router.navigate(['/login'])
};


export const authenGuardChild: CanActivateChildFn = (
  routes,
  state,
) => authenGuard(routes,state)
