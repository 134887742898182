
import { Route } from '@angular/router';
import { MainAppLayoutComponent } from './layout/main-app-layout.component';
import { authenGuard } from './core/guards/authen.guard';



export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/app'
  },
  {
    path: 'auth-callback',
    loadChildren: () => import('./feature/auth-callback/routes').then(r => r.routes)
  },
  {
    path: 'login',
    loadChildren: () => import('./feature/login/routes').then(r => r.ROUTER)
  },
  {
    path: 'app',
    component: MainAppLayoutComponent,
    canActivate: [authenGuard],
    canActivateChild: [authenGuard],
    children: [
      {
        path: 'fo',
        loadChildren: () => import("@poc-monorepo/featureone").then(m => m.ROUTES_FEATURE_ONE),
        data: {
          header: "เมนูชื่อ Fo"
        },
      },
      {
        path: 'gun',
        loadChildren: () => import("@poc-monorepo/waterwork").then(m => m.ROUTER),
        data: {
          header: "เมนูชื่อ Gun"
        },

      },
      {
        path: 'set-plan',
        loadChildren: () => import("@poc-monorepo/set-plan").then(m => m.ROUTER),
        data: {
          header: "แผนงาน"
        },

      },
      {
        path: 'approve-plan',
        loadChildren: () => import("@poc-monorepo/set-plan").then(m => m.ROUTER),
        data: {
          header: "แผนงาน"
        },

      },
      {
        path: 'create-user',
        loadChildren: () => import("@poc-monorepo/create-user").then(m=>m.ROUTER),
        data: {
          header: "จัดการผู้ใช้งาน"
        },

      },
      {
        path: 'edit-profile',
        loadChildren: () => import("@poc-monorepo/edit-profile").then(m=>m.ROUTER),
        data: {
          header: "ข้อมูลผู้ใช้งาน"
        },

      },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/login'
  }
];
