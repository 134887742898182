import { SeItem } from '@poc-monorepo/shared';

export const routes: SeItem[] = [
  // {
  //   id: 'dashboard',
  //   code: '001',
  //   title: 'แดชบอร์ด',
  //   type: 'basic',
  //   icon: 'dashboard',
  //   link: '/app/fo',
  //   children: [],
  // },
  {
    id: 'plan',
    code: '002',
    title: 'แผนงาน',
    type: 'collapsable',
    icon: 'work_history',
    role: ['A','U'],
    children: [
      {
        id: 'set-plan',
        code: '002-000',
        title: 'ร่างแผนจดมาตร',
        type: 'basic',
        // icon: 'work_history',
        link: '/app/set-plan',
        role: ['U'],
        children: [],
      },
      {
        id: 'approve-plan',
        code: '002-001',
        title: 'สร้างเส้นทางอ่านมาตร',
        type: 'basic',
        // icon: 'work_history',
        link: '/app/approve-plan',
        role: ['A'],
        children: [],
      },
      {
        id: 'manage-plan',
        code: '002-002',
        title: 'จัดการแผนงาน',
        type: 'basic',
        // icon: 'work_history',
        link: '/app/manage-plan',
        role: ['U'],
        children: [],
      },
    ],
  },
  {
    id: 'following',
    code: '003',
    title: 'ติดตามผลการจดมาตร',
    type: 'collapsable',
    ischildActive: false,
    icon: 'gas_meter',
    role: ['A','U'],
    children: [
      {
        id: 'report-overall',
        code: '003-001',
        title: 'ภาพรวมการจดมาตร',
        type: 'basic',
        // icon: 'fiber_manual_record',
        link: '/app/overall',
        ischildActive: false,
        role: ['A','U'],
      },
      {
        id: 'report',
        code: '003-002',
        title: 'รายงาน',
        type: 'basic',
        // icon: 'fiber_manual_record',
        link: '/app/report',
        ischildActive: false,
        role: ['A','U'],
      },
    ],
  },
  {
    id: 'manage-user',
    code: '004',
    title: 'จัดการผู้ใช้งาน',
    type: 'collapsable',
    ischildActive: false,
    icon: 'gas_meter',
    role: ['A'],
    children: [
      {
        id: 'create-user',
        code: '004-001',
        title: 'สร้างผู้ใช้งาน',
        type: 'basic',
        // icon: 'fiber_manual_record',
        link: '/app/create-user',
        ischildActive: false,
        role: ['A'],
      },
    ],
  },
  // {
  //   id: 'setting',
  //   code: '007',
  //   title: 'การตั้งค่า',
  //   type: 'collapsable',
  //   icon: 'settings',
  //   children: [
  //     {
  //       id: 'contract',
  //       code: '007',
  //       title: 'สัญญาผู้รับเหมา',
  //       type: 'basic',
  //       // icon: 'work_history',
  //       link: '/app/plan',
  //       children: [],
  //     },
  //   ],
  // },
];
