import {
  APP_INITIALIZER,
  ApplicationConfig,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS,
  withInterceptors,
} from '@angular/common/http';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PICK_FORMATS, AppDateAdapter } from '@poc-monorepo/shared';
import { tokenIntercepter } from './core/intercepter/token-intercepter.interceptor';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { BACKEND_URL } from 'seventy-one-base';
import { environment } from '../environments/environment';
import { appReducers } from './state/app-reducer';
import { metaReducers } from './state/state.config';
import { provideRouterStore } from '@ngrx/router-store';
import { KeycloakService } from 'keycloak-angular';
import { initializeSSOService } from './core/service/keycloak-utility';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      appRoutes,
      withComponentInputBinding()
    ),
    provideAnimationsAsync(),
    provideHttpClient(
      // withInterceptorsFromDi()
      withInterceptors([tokenIntercepter])
    ),

    provideStore(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    provideEffects(),
    provideRouterStore(),
    // provideStoreDevtools(),
    KeycloakService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeSSOService,
    //   multi: true,
    //   deps: [KeycloakService]
    // },
    { provide: LOCALE_ID, useValue: 'th-TH' },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
    { provide: DateAdapter, useClass: AppDateAdapter },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: BACKEND_URL,
      useValue: environment.baseapi,
    },
  ],
};
