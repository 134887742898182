<div class="relative w-full !min-h-full" style="background-color: #edededea;">
  <!-- <div
    class="flex flex-col ease-in-out transition-all duration-500 sm:p-3"
  > -->
    <div class="flex flex-row w-full  relative gap-0 p-3 md:p-0">
      <div
        class="z-40 !h-screen static hidden lg:block transition-all duration-500 ease-in-out lg:translate-x-0 lg:opacity-100"
      >
        <lib-menu-bar [menus]="menu"></lib-menu-bar>
      </div>
      <!--        <div class="flex flex-col w-full h-full" id="main_container">-->
      <!--            <app-tool-bar (expanedMenu)="isExpanedMenu = $event"></app-tool-bar>-->
      <!--            <div class="p-4 m-4 shadow-lg">-->
      <!--                <router-outlet *ngIf="true"></router-outlet>-->
      <!--            </div>-->
      <!--        </div>-->
      <div class="z-0 flex flex-col w-full h-full max-h-screen overflow-auto md:gap-2">
        <div class="block lg:hidden md:m-4">
          <lib-tool-bar class="transition-all duration-500 ease-in-out" [menus]="menu"></lib-tool-bar>
        </div>
        <!-- <lib-tool-bar [menus]="menu"></lib-tool-bar> -->

        <div class="lg:mt-4 mx-4">
          {{ breawdcum$ | async }}
        </div>
        <!-- <div class="breadcrumbs text-sm p-0">
          <ul>
            <li><a>Home</a></li>
            <li><a>Documents</a></li>
            <li>Add Document</li>
          </ul>
        </div> -->
        <div class="my-4 mx-4 md:rounded-xl bg-white md:bg-transparent">
          <!-- Content is Here -->
          <router-outlet class="" *ngIf="true"></router-outlet>
        </div>
      </div>
    </div>
  <!-- </div> -->
</div>
